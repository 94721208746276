import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-pageheader',
    templateUrl: './pageheader.component.html',
    styleUrls: ['./pageheader.component.less'],
})
export class PageheaderComponent {
    @Input() title;
}
