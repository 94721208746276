import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-jga',
  templateUrl: './jga.component.html',
  styleUrls: ['./jga.component.less']
})
export class JgaComponent implements OnInit {
    showFirstQuestion = false;

    form: FormGroup;

    stations = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    selectedStation = 1;

    answerCorrect: boolean;
    solutions = {
        1: ["gusewski anutha", "zivkovic", "sprindt, jahnel", "wendebourg kreuzer"],
        2: ["koffein"],
        3: ["amerigo vespucci"],
        4: ["1,30", "1,40", "1,50", "1,60"],
        5: ["???"],
        6: ["9"],
        7: ["2002"],
        8: ["gemischtes eis"],
        9: ["kegel", "pin"]
    };
    solutionQuestions = {
        1: "Was ist die sechste Zutat von Fritz Kola? Du darfst keine Flasche am Kiosk oder im Supermarkt kaufen.",
        2: "Nach welchem Seefahrer wurde der Platz vor den Elbbrücken in der Hafencity benannt?",
        3: "Was kostet eine Kugel Eis an der Binnenalster bei Warneke?",
        4: "Löse den Fall von Ernie Hudson und der weinenden Frau",
        5: "Wie viele Landungsbrücken gibt es an den Landungsbrücken?",
        6: "In welchem Jahr wurde der Stein für die Hafenpartnerschaft zwischen Hamburg und Yokohama beim Japanischen Garten errichtet?",
        7: "Was ist das billigstes Dessert im Panthera Rodizio Harburg?",
        8: "Welchen Gegenstand zeichnet dieses Programm?",
        9: "Schaffst du alle 9e?"
    };

    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.showFirstQuestion = params['showfirstquestion'] == 'y';
        });

        this.form = this.formBuilder.group({
            answer: ['']
        });
    }

    changeStation(newStation: number) {
        this.form.patchValue({
            answer: ['']
        });
        this.answerCorrect = undefined;
        this.selectedStation = newStation;
    }

    checkAnswer(): void {
        const answer = this.form.value['answer'];
        this.answerCorrect = this.solutions[this.selectedStation].indexOf(answer.toLowerCase()) > -1;
    }
}
