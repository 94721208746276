import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PreparationIngredient } from 'src/app/features/recipe/services/recipe.types';

export interface PreparationStep {
    description: string;
    ingredients: PreparationIngredient[];
    maxSteps: number;
    step: number;
}

@Component({
    selector: 'app-preparationstep',
    templateUrl: './preparationstep.component.html',
    styleUrls: ['./preparationstep.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreparationstepComponent {
    @Input() preparationStep: PreparationStep;
}
