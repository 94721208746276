import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    alerts: any[] = [];

    info(text: string, options: any = {}) {
        this.addAlert(text, 'info', options);
    }

    success(text: string, options: any = {}) {
        this.addAlert(text, 'success', options);
    }

    error(text: string, options: any = {}) {
        this.addAlert(text, 'danger', options);
    }

    addAlert(text: string, type: string, options: any = {}) {
        let headerName;
        switch (type) {
            case 'success': {
                headerName = 'Erfolg';
                break;
            }
            case 'danger': {
                headerName = 'Fehler';
                break;
            }
            default: {
                headerName = 'Information';
            }
        }
        options = {
            ...options,
            classname: 'bg-' + type + ' text-white',
            header: headerName
        };
        this.alerts.push({ text, ...options });
    }

    removeAlert(toast) {
        this.alerts = this.alerts.filter((t) => t !== toast);
    }
}
