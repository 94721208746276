import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Recipe, Ingredient, Tag } from './recipe.types';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class TagService {
    constructor(private http: HttpClient) {}

    getAllTags(): Observable<Tag[]> {
        return this.http.get(environment.backendApiUrl + '/tags', httpOptions) as Observable<Tag[]>;
    }
}
