<header>
    <nav *ngIf="isLoggedIn" class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div [ngbCollapse]="isMenuCollapsed"  class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                    <li>
                        <a class="nav-link text-white" (click)="isMenuCollapsed = true" [routerLink]="['/home']">
                            <i class="navbar-icon fas fa-home d-block mx-auto mb-1"></i>
                            Home
                        </a>
                    </li>
                    <li>
                        <a class="nav-link text-white" (click)="isMenuCollapsed = true" [routerLink]="['/recipe/overview']">
                            <i class="navbar-icon fas fa-blender d-block mx-auto mb-1"></i>
                            Rezepte
                        </a>
                    </li>
                    <!--<li>
                        <a class="nav-link text-white" (click)="isMenuCollapsed = true" [routerLink]="['/training/overview']">
                            <i class="navbar-icon fas fa-dumbbell d-block mx-auto mb-1"></i>
                            Training
                        </a>
                    </li>-->
                </ul>
                <ul class="navbar-nav mb-2 mb-lg-0 col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                    <li>
                        <a class="nav-link text-white" (click)="isMenuCollapsed = true" [routerLink]="['/admin/overview']">
                            <i class="navbar-icon fas fa-user-cog d-block mx-auto mb-1"></i>
                            Admin
                        </a>
                    </li>
                    <li>
                        <a class="nav-link text-white" (click)="isMenuCollapsed = true">
                            <i class="navbar-icon fas fa-user d-block mx-auto mb-1"></i>
                            {{ username | titlecase }}
                        </a>
                    </li>
                    <li>
                        <a class="nav-link text-white" (click)="logout()">
                            <i class="navbar-icon fas fa-sign-out-alt d-block mx-auto mb-1"></i>
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <app-alert></app-alert>
</header>
<body>
    <router-outlet></router-outlet>
</body>
<footer></footer>
