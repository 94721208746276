import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagselectionComponent } from './tagselection/tagselection.component';
import { CounterComponent } from './counter/counter.component';
import { PreparationstepComponent } from './preparationstep/preparationstep.component';
import { PageheaderComponent } from './pageheader/pageheader.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        PageheaderComponent,
        AlertComponent,
        CounterComponent,
        PreparationstepComponent,
        TypeaheadComponent,
        TagselectionComponent,
    ],
    exports: [
        AlertComponent,
        TypeaheadComponent,
        TagselectionComponent,
        CounterComponent,
        PreparationstepComponent,
        PageheaderComponent,
    ],
    imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, RouterModule],
})
export class SharedModule {}
