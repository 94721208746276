import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/login/login.component';
import { NotfoundComponent } from './features/notfound/notfound.component';
import { HomeComponent } from './features/home/home.component';
import { LoggedInGuard } from './core/guards/loggedin.guard';
import { LoggedOutGuard } from './core/guards/loggedout.guard';
import {JgaComponent} from "./features/jgaarne/jga.component";

const routes: Routes = [
    { path: 'login', component: LoginComponent, canActivate: [LoggedOutGuard] },
    { path: 'jga', component: JgaComponent, canActivate: [LoggedOutGuard] },
    { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },
    {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'recipe',
        loadChildren: () => import('./features/recipe/recipe.module').then((m) => m.RecipeModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'training',
        loadChildren: () => import('./features/training/training.module').then((m) => m.TrainingModule),
        canActivate: [LoggedInGuard],
    },
    { path: '**', component: NotfoundComponent, canActivate: [LoggedInGuard] },
    { path: '', pathMatch: 'full', redirectTo: 'login' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
