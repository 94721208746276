import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [HomeComponent, NotfoundComponent],
    imports: [CommonModule, RouterModule],
})
export class FeaturesModule {}
