import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Tag } from 'src/app/features/recipe/services/recipe.types';
import { BehaviorSubject } from 'rxjs';
import { TagService } from 'src/app/features/recipe/services/tag.service';

@Component({
    selector: 'app-tagselection',
    templateUrl: './tagselection.component.html',
    styleUrls: ['./tagselection.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagselectionComponent implements OnInit {
    @Input() form: FormGroup;

    @Output() selectedTagsChanged = new EventEmitter();

    allTags: Tag[] = [];

    availableTags$ = new BehaviorSubject<Tag[]>([]);

    constructor(private tagService: TagService) {}

    ngOnInit(): void {
        this.tagService.getAllTags().subscribe((allTags) => {
            this.allTags = allTags;
            const selectedTags = this.form.value.tags;
            this.availableTags$.next(
                allTags.filter((tag) => !selectedTags.find((selectedTag) => selectedTag.id === tag.id))
            );
        });
        this.form.valueChanges.subscribe((valueChange) => {
            const selectedTags = this.form.value.tags;
            const newTagName = valueChange.newTagName;
            const removeTagName = valueChange.removeTagName;
            if (newTagName && !selectedTags.some((tag) => tag.id === newTagName)) {
                const newTag = this.allTags.find((tag) => tag.name === newTagName);
                if (newTag) {
                    selectedTags.push(newTag);
                    this.availableTags$.next(
                        this.allTags.filter((tag) => !selectedTags.find((recipeTag) => recipeTag.name === tag.name))
                    );
                }
                this.form.controls.newTagName.setValue('');
            } else if (removeTagName) {
                this.form.controls.removeTagName.setValue('');
                this.form.controls.tags.setValue(selectedTags.filter((tag) => tag.name !== removeTagName));
            } else {
                this.availableTags$.next(
                    this.allTags.filter((tag) => !selectedTags.find((selectedTag) => selectedTag.id === tag.id))
                );
            }
            this.selectedTagsChanged.emit();
        });
    }

    removeTag(tagName: string) {
        this.form.controls.newTagName.setValue('');
        this.form.controls.removeTagName.setValue(tagName);
    }
}
