<section>
    <div class="container p-5">
        <div class="ui two column centered grid">
            <div class="column aligned">
                <h1 class="ui teal image header">
                    <div class="content">
                        <i class="microchip icon"></i>
                        Grünhagen NET
                    </div>
                </h1>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container p-5">
        <div class="ui two column centered grid">
            <div class="column">
                <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate class="ui large form">
                    <div class="form-floating mb-3">
                        <input
                            id="username"
                            type="text"
                            class="form-control"
                            name="username"
                            placeholder="Nutzername"
                            [(ngModel)]="form.username"
                            required
                            #username="ngModel"
                        />
                        <label for="username">Nutzername</label>
                        <div class="alert alert-danger mt-3" role="alert" *ngIf="f.submitted && username.invalid">
                            Username ist ein Pflichtfeld
                        </div>
                    </div>
                    <div class="form-floating mb-3">
                        <input
                            id="password"
                            type="password"
                            class="form-control"
                            name="password"
                            placeholder="Passwort"
                            [(ngModel)]="form.password"
                            required
                            #password="ngModel"
                        />
                        <label for="password">Passwort</label>
                        <div class="alert alert-danger mt-3" role="alert" *ngIf="f.submitted && password.invalid">
                            <div *ngIf="password.errors.required">Passwort ist ein Pflichtfeld</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" [disabled]="loginButtonDisabled">
                            Login
                        </button>
                    </div>
                    <div class="form-group">
                        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
                            Login fehlgeschlagen: {{ errorMessage }}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
