import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {SuiModule} from 'ng2-semantic-ui';
import {LoginComponent} from './features/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {authInterceptorProviders} from './core/interceptors/auth.interceptor';
import {FeaturesModule} from './features/features.module';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {JgaComponent} from "./features/jgaarne/jga.component";
import {NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";

@NgModule({
    declarations: [AppComponent, LoginComponent, JgaComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        SuiModule,
        NgbModule,
        FeaturesModule,
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        NgBootstrapFormValidationModule.forRoot(),
        NgBootstrapFormValidationModule
    ],
    providers: [authInterceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {}
