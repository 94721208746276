<section>
    <div class="container mt-5">
        <!--<div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg" [routerLink]="['/calendar']">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Kalender</h2>
                    </div>
                </a>
            </div>
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Wissen</h2>
                    </div>
                </a>
            </div>
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg" [routerLink]="['/movie']">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Filme und Serien</h2>
                    </div>
                </a>
            </div>
        </div>-->
        <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1" [routerLink]="['/recipe/overview']">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Rezepte</h2>
                    </div>
                </a>
            </div>
            <!--<div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg" [routerLink]="['/bowl']">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Bowl</h2>
                    </div>
                </a>
            </div>-->
        </div>
        <!--<div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1" [routerLink]="['/training/overview']">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Training</h2>
                    </div>
                </a>
            </div>
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg" [routerLink]="['/trainingsplan']">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Trainingsplan</h2>
                    </div>
                </a>
            </div>
            <div class="col">
                <a class="card card-cover h-100 overflow-hidden bg-dark rounded-5 shadow-lg" [routerLink]="['/timer']">
                    <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                        <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Timer</h2>
                    </div>
                </a>
            </div>
        </div>-->
    </div>
</section>
