import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { TokenService } from '../../core/services/token.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    form: any = {};
    loginAttempts = 0;
    isLoggedIn = false;
    isLoginFailed = false;
    loginButtonDisabled = false;
    errorMessage = '';
    roles: string[] = [];

    constructor(private authService: AuthService, private tokenService: TokenService) {}

    ngOnInit() {
        if (this.tokenService.getToken()) {
            this.isLoggedIn = true;
        }
    }

    onSubmit() {
        this.loginAttempts++;
        this.authService.login(this.form).subscribe(
            (data) => {
                this.tokenService.saveToken(data.token);
                this.tokenService.saveUser(data);

                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.reloadPage();
            },
            (err) => {
                this.errorMessage = err.error.error;
                this.isLoginFailed = true;
                this.loginButtonDisabled = true;
                setTimeout(() => {
                    this.loginButtonDisabled = false;
                }, this.loginAttempts * 1000);
            }
        );
    }

    reloadPage() {
        window.location.reload();
    }
}
