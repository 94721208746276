<div class="p-3 mt-3 bg-white">
    <p class="preparation-step-count">Schritt {{ preparationStep.step + 1 }} / {{ preparationStep.maxSteps }}</p>
</div>
<table *ngIf="preparationStep.ingredients.length" class="p-3 mt-3 bg-white table">
    <thead>
    <tr>
        <th>Menge</th>
        <th></th>
        <th>Zutat</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let ingredient of preparationStep.ingredients">
        <tr *ngIf="ingredient">
            <td>{{ ingredient.calculatedQuantity | number: '1.2-2' }}</td>
            <td>{{ ingredient.measurement?.abbreviation }}</td>
            <td>{{ ingredient.ingredient.name }}</td>
        </tr>
    </ng-container>
    </tbody>
</table>
<div class="p-3 mt-3 bg-white">
    <p class="lead m-0">{{ preparationStep.description }}</p>
</div>
