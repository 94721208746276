<section>
    <div *ngIf="showFirstQuestion" class="container mb-4">
        <div class="row">
            <div class="col">
                <p>Los geht's mit der Schnitzeljagd durch Hamburg. Fagen wir mit der ersten Frage zur ersten Station an: Wer wohnt jetzt in der alten Butze?</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="mb-4" ngbDropdown>
                    <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle>Station {{ selectedStation }}</button>
                    <div ngbDropdownMenu>
                        <button *ngFor="let station of stations" (click)="changeStation(station)" ngbDropdownItem>
                            Station {{ station }}
                        </button>
                    </div>
                </div>
                <form [formGroup]="form" (validSubmit)="checkAnswer()">
                    <div class="form-group">
                        <label class="pb-1" for="answer">Lösung</label>
                        <input formControlName="answer" type="text" class="form-control" id="answer">
                    </div>
                    <button type="submit" class="btn btn-secondary">Submit</button>
                </form>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div *ngIf="answerCorrect === true" class="row">
            <div class="col">
                <div class="alert alert-success" role="alert">
                    <p>Richtige Antwort!</p>
                    <p>Die neue Aufgabe für Station {{ selectedStation + 1}} lautet wie folgt:</p>
                    <p>{{ solutionQuestions[selectedStation] }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="answerCorrect === false" class="row">
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    Falsche Antwort!
                </div>
            </div>
        </div>
    </div>
</section>
