<ngb-toast
    *ngFor="let alert of alertService.alerts"
    [class]="alert.classname"
    [autohide]="alert.autohide || true"
    [delay]="alert.delay || 3000"
    (hidden)="alertService.removeAlert(alert)"
>
    <ng-template ngbToastHeader>
        <strong class="mx-1">{{ alert.header }}</strong>
    </ng-template>
    {{ alert.text }}
</ngb-toast>
