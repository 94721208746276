<div class="input-group">
    <span *ngIf="label" class="input-group-text">{{ label }}</span>
    <input
        type="text"
        class="form-control"
        [editable]="false"
        [ngbTypeahead]="search"
        [(ngModel)]="model"
        (ngModelChange)="updateModel($event)"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        #instance="ngbTypeahead"
    />
</div>
