<div class="row">
    <div [formGroup]="form" class="col-12 col-sm-5">
        <app-typeahead
            formControlName="newTagName"
            [label]="'Tags'"
            [values]="availableTags$ | async"
        ></app-typeahead>
    </div>
    <div class="col-12 col-sm-7">
        <ng-container *ngFor="let tag of form.value.tags">
            <span class="badge bg-dark me-2">
                {{ tag.name | titlecase }}&nbsp;
                <i class="fa fa-times click" (click)="removeTag(tag.name)"></i>
            </span>
            &nbsp;
        </ng-container>
    </div>
</div>
