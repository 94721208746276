import { Component, OnInit } from '@angular/core';
import { TokenService } from './core/services/token.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    isLoggedIn = false;
    isMenuCollapsed = true;
    username: string;

    constructor(private tokenService: TokenService) {}

    ngOnInit() {
        this.isLoggedIn = !!this.tokenService.getToken();

        if (this.isLoggedIn) {
            const user = this.tokenService.getUser();
            this.username = user.username;
        }
    }

    logout() {
        this.isMenuCollapsed = true
        this.tokenService.signOut();
        window.location.reload();
    }
}
