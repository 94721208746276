import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Tag} from 'src/app/features/recipe/services/recipe.types';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeaheadComponent),
            multi: true
        }
    ]
})
export class TypeaheadComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() parentForm: FormGroup;
    @Input() values: {
        name: string;
    }[];

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    public model: { name: string };

    propagateChange = (_: any) => {};

    formatter = (result: Tag) => result?.name?.replace(result?.name[0], result?.name[0]?.toUpperCase());

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                (term === ''
                    ? this.values
                    : this.values.filter((value) => value?.name?.toLowerCase().indexOf(term.toLowerCase()) > -1)
                )?.slice(0, 10)
            )
        );
    }

    writeValue(value: string) {
        this.model = { name: value };
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    updateModel($event) {
        if ($event?.name) {
            this.propagateChange($event.name);
        }
    }
}
