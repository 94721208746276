<div class="container">
    <h1 class="display-4 mt-4 mb-4">
        {{ title }}
    </h1>
</div>
<div class="container">
    <div class="row">
        <div class="col">
            <ul class="nav p-3 bg-white">
                <ng-content></ng-content>
            </ul>
        </div>
    </div>
</div>
