<div class="input-group">
    <span class="input-group-text">Portionen</span>
    <input
        type="number"
        min="0"
        max="1000"
        class="form-control"
        placeholder="Anzahl"
        [(ngModel)]="counter"
        (ngModelChange)="adjustCounter($event)"
    />
    <span class="input-group-text" (click)="adjustCounter(counter, -1)">-</span>
    <span class="input-group-text" (click)="adjustCounter(counter, 1)">+</span>
</div>
