import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.less'],
})
export class CounterComponent {
    @Input() counter = 1;
    @Output() counterUpdated = new EventEmitter<number>();

    adjustCounter(newValue: number, adjustment: number = 0): void {
        if (newValue > 0) {
            this.counter = newValue + adjustment;
        } else {
            if (adjustment > 0) {
                this.counter = adjustment;
            } else {
                this.counter = 0;
            }
        }

        this.counterUpdated.emit(this.counter);
    }
}
