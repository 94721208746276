import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
    providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate {
    constructor(private tokenService: TokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!!this.tokenService.getToken()) {
            this.router.navigate(['home']);
        }
        return true;
    }
}
